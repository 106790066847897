import {useState, useRef, useEffect} from 'react'
import { convertToBlob, convertToFourDigits, formatDateToDashToServer, getAPIBaseURL, getSiteBaseURL, getUser } from "../../services/helpers";
import { getData, postData } from "../../services/request";
import { validateTitle, validateText, validateName, validateEmail, validatePhoneNumber, validateGender, validateDate, validateNIN, validateLink, validateState, validateLocalGovernment, validateAddress, validateNumber, validateStatus,validateOLevelCode, validateALevelCode, validateUsername, validateInstitution, validateImage, validateCombination, validateMiddleName, validateAgreement, validateSubjects } from "../../services/validators";
import { aLevelCourses, localGovernments, oLevelCourses } from '../../data';
import { WebcamCapture } from '../../components/CustomWebCam';
import Popup from '../../components/Popup';
import InfoPopup from '../../components/InfoPopup';
import Notification from '../../components/Notification'
import { examsTypeList, gradesList, subjectsList } from '../../constants';

const NewLit = ({setExternalLit, setPage, setLastPage, lastPage}) => {
   const surnameRef = useRef(null)
   const firstNameRef = useRef(null)
   const middleNameRef = useRef(null)
   const phoneNumberRef = useRef(null)
   const emailRef = useRef(null)
   const genderRef = useRef(null)
   const dobRef = useRef(null)
   const ninRef = useRef(null)
   const photoRef = useRef(null)
   const stateRef = useRef(null)
   // const localGovernmentRef = useRef(null)
   const institutionNumberRef = useRef(null)
   const oLevelCodeRef = useRef(null)
   const aLevelCodeRef = useRef(null)
   const agreementRef = useRef(null)
   
    
    const [user, setUser] = useState({})
    //const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [surname, setSurname] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState()
    const [dob, setDOB] = useState("")
    const [nin, setNIN] = useState("")
    const [photo, setPhoto] = useState("")
    const [ninPhoto, setNinPhoto] = useState("")
    const [state, setState] = useState()
    const [centerId, setCenterId] = useState("")
    const [institutionNumber, setInstitutionNumber] = useState("")
    const [oLevelCode, setOLevelCode] = useState('')
    const [aLevelCode, setALevelCode] = useState('')
    const [role, setRole] = useState("")
    const [status, setStatus] = useState(0)
    const [agreement, setAgreement] = useState(0)
    const [nigerianStates, setNigerianStates] = useState([]) 
    const [oLevelSubjects, setOLevelSubjects] = useState([]) 
    const [aLevelSubjects, setALevelSubjects] = useState([])
    const [uploadCapture, setUploadCapture] = useState(false)
    const [liveCapture, setLiveCapture] = useState(false)
    const [photoURL, setPhotoURL] = useState(null)
    const [photoMethod, setPhotoMethod] = useState()
    const [imageCapture, setImageCapture] = useState(null)

    const [ninUploadCapture, setNinUploadCapture] = useState(false)
    const [ninLiveCapture, setNinLiveCapture] = useState(false)
    const [ninPhotoURL, setNinPhotoURL] = useState(null)
    const [ninPhotoMethod, setNinPhotoMethod] = useState()
    const [ninImageCapture, setNinImageCapture] = useState(null)
    

    const [stateLoading, setStateLoading] = useState(false) 
    const [oLevelLoading, setOLevelLoading] = useState(false)
    const [aLevelLoading, setALevelLoading] = useState(false)


    const [subject1, setSubject1] = useState('');
    const [grade1, setGrade1] = useState('');
    const [examNumber1, setExamNumber1] = useState('');
    const [examType1, setExamType1] = useState('');
    const [year1, setYear1] = useState('');
  
    const [subject2, setSubject2] = useState('');
    const [grade2, setGrade2] = useState('');
    const [examNumber2, setExamNumber2] = useState('');
    const [examType2, setExamType2] = useState('');
    const [year2, setYear2] = useState('');
  
    const [subject3, setSubject3] = useState('');
    const [grade3, setGrade3] = useState('');
    const [examNumber3, setExamNumber3] = useState('');
    const [examType3, setExamType3] = useState('');
    const [year3, setYear3] = useState('');
  
    const [subject4, setSubject4] = useState('');
    const [grade4, setGrade4] = useState('');
    const [examNumber4, setExamNumber4] = useState('');
    const [examType4, setExamType4] = useState('');
    const [year4, setYear4] = useState('');
  
    const [subject5, setSubject5] = useState('');
    const [grade5, setGrade5] = useState('');
    const [examNumber5, setExamNumber5] = useState('');
    const [examType5, setExamType5] = useState('');
    const [year5, setYear5] = useState('');
  
    const [subject6, setSubject6] = useState('');
    const [grade6, setGrade6] = useState('');
    const [examNumber6, setExamNumber6] = useState('');
    const [examType6, setExamType6] = useState('');
    const [year6, setYear6] = useState('');
  
    const [subject7, setSubject7] = useState('');
    const [grade7, setGrade7] = useState('');
    const [examNumber7, setExamNumber7] = useState('');
    const [examType7, setExamType7] = useState('');
    const [year7, setYear7] = useState('');
  
    const [subject8, setSubject8] = useState('');
    const [grade8, setGrade8] = useState('');
    const [examNumber8, setExamNumber8] = useState('');
    const [examType8, setExamType8] = useState('');
    const [year8, setYear8] = useState('');
  
    const [subject9, setSubject9] = useState('');
    const [grade9, setGrade9] = useState('');
    const [examNumber9, setExamNumber9] = useState('');
    const [examType9, setExamType9] = useState('');
    const [year9, setYear9] = useState('');

    const [subjectFields, setSubjectFields] = useState([
        {
          setSubject1, 
          setGrade1, 
          setExamNumber1, 
          setExamType1, 
          setYear1
        },
        {
          setSubject2, 
          setGrade2, 
          setExamNumber2, 
          setExamType2, 
          setYear2
        },
        {
          setSubject3, 
          setGrade3, 
          setExamNumber3, 
          setExamType3, 
          setYear3
        },
        {
          setSubject4, 
          setGrade4, 
          setExamNumber4, 
          setExamType4, 
          setYear4
        },
        {
          setSubject5, 
          setGrade5, 
          setExamNumber5, 
          setExamType5, 
          setYear5
        },
        {
          setSubject6, 
          setGrade6, 
          setExamNumber6, 
          setExamType6, 
          setYear6
        },
        {
          setSubject7, 
          setGrade7, 
          setExamNumber7, 
          setExamType7, 
          setYear7
        },
        {
          setSubject8, 
          setGrade8, 
          setExamNumber8, 
          setExamType8, 
          setYear8
        },
        {
          setSubject9, 
          setGrade9, 
          setExamNumber9, 
          setExamType9, 
          setYear9
        }
      ]);
      

  
    

    const [selectedDate, setSelectedDate] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    
    const [text, setText] = useState("")
    const [publish, setPublish] = useState("0")
    const [author, setAuthor] = useState("")
    const [action, setAction] = useState("save")
    const [loading, setLoading] = useState("loading........")
    const [notification, setNotification] = useState(false)
    const [infoPopup, setInfoPopup] = useState(false) 
    const [placements, setPlacements] = useState([1,2,3,4,5,6,7,8,9])
    const [subjectErrors, setSubjectErros] = useState([])
    const [subjectError, setSubjectError] = useState('')
    const [error, setError] = useState([{field: "firstName", msg:""}, {field: "middleName", msg:""}, {field: "surname", msg:""},
    {field: "phoneNumber", msg:""}, {field: "email", msg:""}, {field: "gender", msg:""}, {field: "dob", msg:""}, {field: "nin", msg:""}, {field: "photo", msg:""}, {field: "ninPhoto", msg:""},
    {field: "state", msg:""}, {field: "localGovernment", msg:""}, {field: "institutionNumber", msg:""},
    {field: "oLevelCode", msg:""}, {field: "aLevelCode", msg:""}, {field: "agreement", msg:""}, 
    {field: "status", msg:""}, 
    { field: "subject1", msg: "" }, { field: "grade1", msg: "" }, { field: "examNumber1", msg: "" }, { field: "examType1", msg: "" }, { field: "year1", msg: ""},
    { field: "subject2", msg: "" },
    { field: "grade2", msg: "" },
    { field: "examNumber2", msg: "" },
    { field: "examType2", msg: "" },
    { field: "year2", msg: "" },
    
    { field: "subject3", msg: "" },
    { field: "grade3", msg: "" },
    { field: "examNumber3", msg: "" },
    { field: "examType3", msg: "" },
    { field: "year3", msg: "" },
  
    { field: "subject4", msg: "" },
    { field: "grade4", msg: "" },
    { field: "examNumber4", msg: "" },
    { field: "examType4", msg: "" },
    { field: "year4", msg: "" },
  
    { field: "subject5", msg: "" },
    { field: "grade5", msg: "" },
    { field: "examNumber5", msg: "" },
    { field: "examType5", msg: "" },
    { field: "year5", msg: "" },
  
    { field: "subject6", msg: "" },
    { field: "grade6", msg: "" },
    { field: "examNumber6", msg: "" },
    { field: "examType6", msg: "" },
    { field: "year6", msg: "" },
  
    { field: "subject7", msg: "" },
    { field: "grade7", msg: "" },
    { field: "examNumber7", msg: "" },
    { field: "examType7", msg: "" },
    { field: "year7", msg: "" },
  
    { field: "subject8", msg: "" },
    { field: "grade8", msg: "" },
    { field: "examNumber8", msg: "" },
    { field: "examType8", msg: "" },
    { field: "year8", msg: "" },
  
    { field: "subject9", msg: "" },
    { field: "grade9", msg: "" },
    { field: "examNumber9", msg: "" },
    { field: "examType9", msg: "" },
    { field: "year9", msg: "" },
    ]);

    const [genError, setGenError] = useState("")

    useEffect( () => {
        const user = getUser()
        if(!user){
          window.location.href = `${getSiteBaseURL()}/login`
        }else{
            setUser(user)
        }  
      /*if(localStorage.getItem('jwt_token') == "" || localStorage.getItem('jwt_token') == null || localStorage.getItem('jwt_token') == undefined){
            window.location.href = `${getSiteBaseURL()}/login`
        }*/
    }, [])

    useEffect(() => {
        if(user){
            loadStates()
            loadOLevelSubjects()
            loadALevelSubjects()
        }
    }, [])

    useEffect(() => {
        //alert(imageCapture)
        if(imageCapture){
            setPhoto(imageCapture)
        }
    }, [imageCapture])


    /*useEffect(() => {
        if(user && state){
            //alert('Loading new local Governments')
            loadLocalGovernments(state)
        }
    }, [state])*/

    useEffect( () => {
        if(notification){
            setTimeout(() => {
                setNotification(false)
                setPage('SingleLit')
                setLastPage('Lits')
            }, 3600)
        }
    }, [notification])

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    
    const handleNIN = () => {
        var inputElement = document.getElementById("NIN");
        var inputValue = inputElement.value;
        
        // Remove non-numeric characters using a regular expression
        inputValue = inputValue.replace(/[^0-9]/g, '');
        
        // Update the input value with the cleaned numeric value
        inputElement.value = inputValue;
    }

    const handlePhoneNumber = () => {
        var inputElement = document.getElementById("phoneNumber");
        var inputValue = inputElement.value;
        
        // Remove non-numeric characters using a regular expression
        inputValue = inputValue.replace(/[^0-9]/g, '');
        
        // Update the input value with the cleaned numeric value
        inputElement.value = inputValue;
    }

    const handleDateChange = (event) => {
        const inputDate = new Date(event.target.value);
        const formattedDate = formatDate(inputDate);
    
        // Update state with the selected and formatted dates
        setSelectedDate(event.target.value);
        setFormattedDate(formattedDate);
    };    

    
    
    

    
    const setLit = async (user) => {
        //alert("Title: ");
        //alert(photo.type)
        // alert(validateAgreement(agreement).result)
        var accessToken = localStorage.getItem('token');
        var centerId = user._id
        
        var firstNameVal = validateName(firstName).error == "" ? true: false;
        var middleNameVal = validateMiddleName(middleName).error == "" ? true: false;
        var surnameVal = validateName(surname).error == "" ? true: false;
        var phoneNumberVal = validatePhoneNumber(phoneNumber).error == "" ? true: false;
        var emailVal = validateEmail(email).error == "" ? true: false;
        var genderVal = validateGender(gender).error == "" ? true: false;
        var dobVal = validateDate(dob).error == "" ? true: false;
        var ninVal = validateNIN(nin).error == "" ? true: false;
        var photoVal = validateImage(photo).error == "" ? true: false;
        var ninPhotoVal = validateImage(ninPhoto).error == "" ? true: false;
        var stateVal = validateState(state).error == "" ? true: false;

        /* var subject1Val = validateName(subject1).error == "" ? true: false;
        var grade1Val = validateName(grade1).error == "" ? true: false;
        var examType1Val = validateName(examType1).error == "" ? true: false;
        var examNumber1Val = validateName(examNumber1).error == "" ? true: false;
        var year1Val = validateName(year1).error == "" ? true: false; */
        
        //subjects validation
        var subject1Val = validateName(subject1).error == "" ? true : false;
        var grade1Val = validateInstitution(grade1).error == "" ? true : false;
        var examType1Val = validateName(examType1).error == "" ? true : false;
        var examNumber1Val = validateInstitution(examNumber1).error == "" ? true : false;
        var year1Val = validateNumber(year1).error == "" ? true : false;

        var subject2Val = validateName(subject2).error == "" ? true : false;
        var grade2Val = validateInstitution(grade2).error == "" ? true : false;
        var examType2Val = validateName(examType2).error == "" ? true : false;
        var examNumber2Val = validateInstitution(examNumber2).error == "" ? true : false;
        var year2Val = validateNumber(year2).error == "" ? true : false;

        var subject3Val = validateName(subject3).error == "" ? true : false;
        var grade3Val = validateInstitution(grade3).error == "" ? true : false;
        var examType3Val = validateName(examType3).error == "" ? true : false;
        var examNumber3Val = validateInstitution(examNumber3).error == "" ? true : false;
        var year3Val = validateNumber(year3).error == "" ? true : false;

        var subject4Val = validateName(subject4).error == "" ? true : false;
        var grade4Val = validateInstitution(grade4).error == "" ? true : false;
        var examType4Val = validateName(examType4).error == "" ? true : false;
        var examNumber4Val = validateInstitution(examNumber4).error == "" ? true : false;
        var year4Val = validateNumber(year4).error == "" ? true : false;

        var subject5Val = validateName(subject5).error == "" ? true : false;
        var grade5Val = validateInstitution(grade5).error == "" ? true : false;
        var examType5Val = validateName(examType5).error == "" ? true : false;
        var examNumber5Val = validateInstitution(examNumber5).error == "" ? true : false;
        var year5Val = validateNumber(year5).error == "" ? true : false;

        

        // var localGovernmentVal = validateLocalGovernment(localGovernment).error == "" ? true: false;
        //var districtVal = validateAddress(district).error == "" ? true: false;
        //var houseAddressVal = validateAddress(houseAddress).error == "" ? true: false;
        //var examYearVal = validateDate(examYear).error == "" ? true: false;
        var institutionNumberVal = validateInstitution(institutionNumber).error == "" ? true: false;
        var oLevelCodesVal =validateOLevelCode(oLevelCode).error == "" ? true: false;
        var aLevelCodeVal = validateALevelCode(aLevelCode).error == "" ? true: false;
        var statusVal = validateStatus(status).error == "" ? true: false;
        var agreementVal = validateAgreement(agreement).error == "" ? true: false;
        var combinationVal = validateCombination(oLevelCode, aLevelCode).error == "" ? true : false;

        //var tex_val = validateText(text).error == "" ? true: false;
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        //var ui_val = user_id == "" || user_id == undefined? false : true; 
        //var un_val = user_name == "" || user_name == undefined? false : true; 
        
        //if(accessToken == "") setGenError("Unauthorized user. Login again!"); 
        //if(user_id == "") setGenError("Unauthorized user, no id. Login again!"); 
        //if(user_name == "")  setGenError("Unauthorized user, no name. Login again!"); 
        //setError([...error, error.find(item => item.field == "title").msg = validateName(title).result])
        setError([...error, error.find(item => item.field == "firstName").msg = validateName(firstName).result])
        setError([...error, error.find(item => item.field == "middleName").msg = validateMiddleName(middleName).result])
        setError([...error, error.find(item => item.field == "surname").msg = validateName(surname).result])
        setError([...error, error.find(item => item.field == "phoneNumber").msg = validatePhoneNumber(phoneNumber).result])
        setError([...error, error.find(item => item.field == "email").msg = validateEmail(email).result])
        setError([...error, error.find(item => item.field == "gender").msg = validateGender(gender).result])
        setError([...error, error.find(item => item.field == "dob").msg = validateDate(dob).result])
        setError([...error, error.find(item => item.field == "nin").msg = validateNIN(nin).result])
        setError([...error, error.find(item => item.field == "photo").msg = validateImage(photo).result])
        setError([...error, error.find(item => item.field == "ninPhoto").msg = validateImage(ninPhoto).result])
        setError([...error, error.find(item => item.field == "state").msg = validateState(state).result])
        // setError([...error, error.find(item => item.field == "localGovernment").msg = validateLocalGovernment(localGovernment).result])
        //setError([...error, error.find(item => item.field == "district").msg = validateAddress(district).result])
        //setError([...error, error.find(item => item.field == "houseAddress").msg = validateAddress(houseAddress).result])
        //setError([...error, error.find(item => item.field == "examYear").msg = validateDate(examYear).result])
        setError([...error, error.find(item => item.field == "institutionNumber").msg = validateInstitution(institutionNumber).result])
        setError([...error, error.find(item => item.field == "oLevelCode").msg =validateOLevelCode(oLevelCode).result])
        setError([...error, error.find(item => item.field == "aLevelCode").msg = validateALevelCode(aLevelCode).result])
        setError([...error, error.find(item => item.field == "status").msg = validateStatus(status).result])
        setError([...error, error.find(item => item.field == "agreement").msg = validateAgreement(agreement).result])
        
        setError([...error, error.find(item => item.field == "aLevelCode").msg = validateCombination(oLevelCode, aLevelCode).result])
        const allSubjects = [
            { subject1 : subject1, grade1, examType1, examNumber1, year1 },
            { subject2, grade2, examType2, examNumber2, year2 },
            { subject3, grade3, examType3, examNumber3, year3 },
            { subject4, grade4, examType4, examNumber4, year4 },
            { subject5, grade5, examType5, examNumber5, year5 },
            { subject6, grade6, examType6, examNumber6, year6 },
            { subject7, grade7, examType7, examNumber7, year7 },
            { subject8, grade8, examType8, examNumber8, year8 },
            { subject9, grade9, examType9, examNumber9, year9 }
        ]

        // const allSub = [{subject1, subject2}, {subject3, subject4}]
         // setSubjectErros(validateSubjects(allSubjects))
         // const subjectErrors2 = validateSubjects(allSubjects)

        if(firstNameVal && surnameVal && phoneNumberVal && emailVal && genderVal && dobVal && ninVal && photoVal && ninPhotoVal && stateVal && institutionNumberVal && agreementVal && combinationVal){
            if (
                subject1Val && grade1Val && examType1Val && examNumber1Val && year1Val &&
                subject2Val && grade2Val && examType2Val && examNumber2Val && year2Val &&
                subject3Val && grade3Val && examType3Val && examNumber3Val && year3Val &&
                subject4Val && grade4Val && examType4Val && examNumber4Val && year4Val &&
                subject5Val && grade5Val && examType5Val && examNumber5Val && year5Val
            ) {
            
            console.log("All subjects and fields are valid.");
            setAction("Loading...")
            const url = `${getAPIBaseURL()}/v1/students`;
            // const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            // const headers = { 'Content-Type' : 'multipart/form-data', 'token': accessToken}
            const headers = { 'Content-Type' : 'multipart/form-data'}
             // alert(typeof(allSubjects))           

            //alert(validSubjects)
            // const data = {firstName, middleName, surname, phoneNumber, email, gender, dob, nin, photo, state, institutionNumber, oLevelCode, aLevelCode, role: 'STUDENT', centerId};
            //const formatedDate = formatDateToDashToServer(dob)
            const formData = new FormData()
            formData.append('firstName', firstName.trim())
            formData.append('middleName', middleName.trim())
            formData.append('surname', surname.trim())
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)
            formData.append('gender', gender)
            formData.append('dob', dob)
            formData.append('nin', nin)
            formData.append('state', state)
            // formData.append('localGovernment', localGovernment)
            formData.append('institutionNumber', institutionNumber.trim())
            formData.append('oLevelCode', oLevelCode)
            formData.append('aLevelCode', aLevelCode)
            formData.append('subjects', JSON.stringify(allSubjects))
            //formData.append('status', status)
            //alert('Center Number: ' + user?.number)
            formData.append('centerNumber', user.number)
            formData.append('centerId', centerId)
            const convertedPhoto = await convertToBlob(photo)
            const convertedNinPhoto = await convertToBlob(ninPhoto)
            formData.append('photo', convertedPhoto)
            formData.append('ninPhoto', convertedNinPhoto)
            //alert("a Level: " + aLevelCode + " o Level: " + oLevelCode)
            
            const requestResult = await postData(url, headers, formData)
            setAction('save')
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 201){
                    //alert("Student Successfully added")
                    setNotification(true)
                    //alert(JSON.stringify(requestResult.result.data.data.student))
                    setExternalLit(requestResult.result.data.data.student)
                    //alert(JSON.stringify(requestResult.result.data.data))
                    //setPage('Lit')
                }else{
                    setInfoPopup(true)
                    setGenError('something went wrong')
                }

            }else{
                setInfoPopup(true)
                setGenError(requestResult.result.data.message ? requestResult.result.data.message : requestResult.result.data.data)
            }

        } else {
            // One or more validations failed
            setSubjectError("Some subjects or fields are invalid.");
            /*alert(
                `Subject 1: ${subject1Val}, Grade: ${grade1Val}, Exam Type: ${examType1Val}, Exam Number: ${examNumber1Val}, Year: ${year1Val}\n` +
                `Subject 2: ${subject2Val}, Grade: ${grade2Val}, Exam Type: ${examType2Val}, Exam Number: ${examNumber2Val}, Year: ${year2Val}\n` +
                `Subject 3: ${subject3Val}, Grade: ${grade3Val}, Exam Type: ${examType3Val}, Exam Number: ${examNumber3Val}, Year: ${year3Val}\n` +
                `Subject 4: ${subject4Val}, Grade: ${grade4Val}, Exam Type: ${examType4Val}, Exam Number: ${examNumber4Val}, Year: ${year4Val}\n` +
                `Subject 5: ${subject5Val}, Grade: ${grade5Val}, Exam Type: ${examType5Val}, Exam Number: ${examNumber5Val}, Year: ${year5Val}`
            );*/
            
        }    


        }else{

            setInfoPopup(true)
            
            if(!agreementVal) agreementRef.current.focus()
            if(!photoVal) photoRef.current.focus()
            if(!oLevelCodesVal) oLevelCodeRef.current.focus()
            if(!aLevelCodeVal) aLevelCodeRef.current.focus()
            if(!combinationVal) aLevelCodeRef.current.focus()
            // if(!localGovernmentVal) localGovernmentRef.current.focus()
            if(!stateVal) stateRef.current.focus()
            if(!ninVal) ninRef.current.focus()
            if(!emailVal) emailRef.current.focus()
            if(!phoneNumberVal) phoneNumberRef.current.focus()
            if(!dobVal) dobRef.current.focus()
            if(!genderVal) genderRef.current.focus()
            if(!middleNameVal) middleNameRef.current.focus()
            if(!firstNameVal) firstNameRef.current.focus()
            if(!surnameVal) surnameRef.current.focus()
            
            /* firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus()
            firstNameVal ? null : firstNameRef.current.focus() 
            && surnameVal && phoneNumberVal && emailVal && genderVal && dobVal && ninVal && photoVal && stateVal && localGovernmentVal  && agreementVal && combinationVal
                */
        } 
    }

    const loadStates = async () => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        // alert('getting states..')
        if(nigerianStates.length == 0){
            // var accessToken = localStorage.getItem('token');
            const user = getUser()
            //var at_val = accessToken == "" || accessToken == undefined? false : true; 
            /* if(accessToken == "" || user.length == 0 || undefined) setGenError("Unauthorized user. Login again!")*/ 
            
            if(user){
                //alert("going")
                const url = `${getAPIBaseURL()}/v1/states`;
                const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
                // const headers = {'token': accessToken}
                const headers = {}
                // const params = {centerId: user._id, limit:limit , skip:skip};
                const params = {}

                setStateLoading(true)
                const requestResult = await getData(url, headers, params)
                setStateLoading(false)
                if(requestResult.error == ""){
                    //alert('No error')
                    //alert(requestResult.result.status)
                    if(requestResult.result.status === 200){
                        setNigerianStates([...nigerianStates, ...requestResult.result.data.data])
                        
                    }else{
                        // alert('Something from logic')
                        setGenError('something went wrong')
                    }

                }else{
                    // alert('Result: ' + requestResult.result.data.status)
                    // console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                    setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                }
                
            }  
        }
    }

    /* const loadLocalGovernments = async (stateCode) => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('getting lits..')
        // var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        // if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user){
            //alert("going")
            const url = `${getAPIBaseURL()}/v1/localgovernments`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            // const headers = {'token': accessToken}
            const headers = {}
            // const params = {centerId: user._id, limit:limit , skip:skip};
            const params = {stateCode: stateCode}
            
            setLocalGovernmentLoading(true)
            const requestResult = await getData(url, headers, params)
            setLocalGovernmentLoading(false)
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    // setLocalGovernment('')
                    setStateLocalGovernments([...requestResult.result.data.data])
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                //alert('error from server')
                //console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
            }
            
        }
    } */  

        const loadOLevelSubjects = async () => {
            //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
            //alert('getting lits..')
            if(oLevelSubjects.length == 0){
                //var accessToken = localStorage.getItem('token');
                const user = getUser()
                //var at_val = accessToken == "" || accessToken == undefined? false : true; 
                // if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
                
                if(user){
                    //alert("going")
                    const url = `${getAPIBaseURL()}/v1/combinations/olevel`;
                    const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
                    // const headers = {'token': accessToken}
                    const headers = {}
                    // const params = {centerId: user._id, limit:limit , skip:skip};
                    const params = {}

                    
                        const requestResult = await getData(url, headers, params)
                    
                        if(requestResult.error == ""){
                            //alert('No error')
                            //alert(requestResult.result.status)
                            if(requestResult.result.status === 200){
                                setOLevelSubjects([...oLevelSubjects, ...requestResult.result.data.data])
                                
                            }else{
                                setGenError('something went wrong')
                            }
            
                        }else{
                            //alert('error from server')
                           // console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                        }
                    }
                    
            }
        }    

            const loadALevelSubjects = async () => {
                //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
                //alert('getting lits..')
                if(aLevelSubjects.length == 0){
                    // var accessToken = localStorage.getItem('token');
                    const user = getUser()
                    //var at_val = accessToken == "" || accessToken == undefined? false : true; 
                    // if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
                    
                    if(user){
                        //alert("going")
                        const url = `${getAPIBaseURL()}/v1/combinations/alevel`;
                        const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
                        // const headers = {'token': accessToken, 'Content-Type' : ''}
                        const headers = {}
                        // const params = {centerId: user._id, limit:limit , skip:skip};
                        const params = {}
            
                        const requestResult = await getData(url, headers, params)
                        
                        if(requestResult.error == ""){
                            //alert('No error')
                            //alert(requestResult.result.status)
                            if(requestResult.result.status === 200){
                                setALevelSubjects([...aLevelSubjects, ...requestResult.result.data.data])
                                
                            }else{
                                setGenError('something went wrong')
                            }
            
                        }else{
                            //alert('error from server')
                            //console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                        }
                        
                    } 
                }    

            }   
    
    const handlePhotoUpload = (e) => {
        // alert(JSON.stringify(e))
        setPhoto(e.target.files[0])
        setPhotoURL(URL.createObjectURL(e.target.files[0]))
        setUploadCapture(true)
        
        /*const file = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setPhotoURL(fileReader.result);
        });
        fileReader.readAsDataURL(file);*/
    }     

    const handleNinPhotoUpload = (e) => {
        // alert(JSON.stringify(e))
        setNinPhoto(e.target.files[0])
        setNinPhotoURL(URL.createObjectURL(e.target.files[0]))
        setNinUploadCapture(true)
        
        /*const file = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setPhotoURL(fileReader.result);
        });
        fileReader.readAsDataURL(file);*/
    }     
    
    const navigateToViewPage = (lit) => {
        setLit(lit)
        setPage("SingleLit")
        setLastPage("Lits")
    }

    const setNull = () => {
        return null
    }

    return(
        
        <div>
           {notification && <Notification message="New student is successfully registered" setNotification={setNotification}/>}

        <div class="mx-5 md:mx-20 mt-10 mb-10">
            <div class="flex items-center mb-10">
                <div>
                    <button onClick={() => {setPage(lastPage);}} type="button" class="text-white bg-gray-700 hover:bg-black focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div className='ml-2 mb-2'>
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Register New Candidate</h1>
                </div>
            </div>
            <div class="my-5">
                {genError != "" ? <div class="text-red-500 text-sm font-semibold">{genError}</div> : ""}
            </div>

            {infoPopup && <InfoPopup setInfoPopup={setInfoPopup} heading={"Candidate Registration"} text={"Error in form"}/>}
          

            {/*<div className='grid grid-cols-2 gap-4'>*/}
            <form autoComplete='off'>
                    
                <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>    
                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Surname</label>
                        <input onChange={(e) => setSurname(e.target.value)} ref={surnameRef} maxLength={25} type="titile" name="surname" id="surname" class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "surname").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "surname").msg }</p>: null}
                    </div>
                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">First Name</label>
                        <input onChange={(e) => setFirstName(e.target.value)} ref={firstNameRef} maxLength={25} type="title" id="firstName" name="firstName" class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "firstName").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "firstName").msg }</p>: null}
                    </div>
                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Middle Name</label>
                        <input onChange={(e) => setMiddleName(e.target.value)} ref={middleNameRef} maxLength={25} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 uppercase text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "middleName").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "middleName").msg }</p>: null}
                    </div>

                </div> 


                <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>
                    <div className="sm:col-span-3 mb-6 w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Gender</label>
                    <div className="mt-2">
                        <select onChange={(e) => setGender(e.target.value)} ref={genderRef}
                        id="gender"
                        name="gender"
                        autoComplete="country-name"
                        class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                        <option value="">--select--</option>  
                        <option value={1}>Male</option>
                        <option value={2}>Female</option>
                        </select>
                        {error.find(item => item.field == "gender").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "gender").msg }</p>: null}
                    </div>
                </div>


                <div class="mb-6 w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Date of Birth</label>
                    <input onChange={(e) => setDOB(e.target.value)} ref={dobRef} type="date"  datatype='' id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                    {error.find(item => item.field == "dob").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "dob").msg }</p>: null}
                </div>

               </div> 
            
               <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>
                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Phone Number</label>
                        <input onChange={(e) => {handlePhoneNumber(); setPhoneNumber(e.target.value)}} ref={phoneNumberRef} maxLength={11} type="text" id="phoneNumber" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "phoneNumber").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "phoneNumber").msg }</p>: null}
                    </div>

                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Email Address</label>
                        <input onChange={(e) => setEmail(e.target.value)} ref={emailRef} maxLength={150} type="titile" id="titile" class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "email").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "email").msg }</p>: null}
                    </div>
                </div>


                    <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>
               
                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">NIN Number</label>
                        <input onChange={(e) => {handleNIN(); setNIN(e.target.value)}} ref={ninRef} type='text' maxLength={11} id="NIN" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "nin").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "nin").msg }</p>: null}
                    </div>

                    <div class="mb-6 w-full">
                        <div className="sm:col-span-3 w-full">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                Upload NIN slip
                            </label>
                            
                                {ninUploadCapture ? 
                                    <div>
                                        <img src= {ninPhotoURL} className='w-24 h-24' />
                                        <button onClick={() => {setNinUploadCapture(false); setNinPhotoURL(null); setNinPhoto(null)}} type="button" class="mt-2 text-white bg-red-500 hover:bg-red-600  focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">remove</button>
                                    </div> 
                                    : <div class="flex items-center bg-grey-lighter">
                                    <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                        </svg>
                                        <span class="mt-2 text-base leading-normal">Select a file</span>
                                        <input type='file' class="hidden" onChange={handleNinPhotoUpload}/>
                                    </label>
                                </div>}
                                {error.find(item => item.field == "ninPhoto").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "ninPhoto").msg }</p>: null}
                            </div>
                    </div>
                    </div>
                {/*<div class="mb-6">
                    <label for="titile" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gender</label>
                    <input onChange={(e) => setGender(e.target.value)} type="titile" id="titile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="What is bitcoin?" required=""/>
                    {error.find(item => item.field == "gender").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "gender").msg }</p>: null}
                </div>*/}
                <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>
                    <div class="mb-6 w-full">   
                    <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                        State of Origin
                    </label>
                    <div className="mt-2">
                        <select onChange={(e) => setState(e.target.value)} ref={stateRef}
                        id="state"
                        name="state"
                        autoComplete="country-name"
                        class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                        <option value="">{stateLoading ? 'Loading...' : '--select--' }</option>  
                        {nigerianStates.map((theState, i) => 
                            <option value={theState.code}>{theState.name}</option>
                        )}
                        </select>
                        {error.find(item => item.field == "state").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "state").msg }</p>: null}
                    </div>
                    </div>
                    </div>

                    {/* <div class="mb-6 w-full">   
                        <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Local Government of Origin
                        </label>
                        <div className="mt-2">
                            <select onChange={(e) => setLocalGovernment(e.target.value)} ref={localGovernmentRef}
                            id="state"
                            name="state"
                            autoComplete="country-name"
                            class="bg-white border border-gray-300 text-gray-900 uppercase text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                            <option value=''>{localGovernmentLoading ? 'Loading...' : '--select--' }</option>  
                            {stateLocalGovernments.map((theLoc, i) => 
                                <option value={theLoc.code}>{theLoc.name}</option> 
                            )}
                            </select>
                            {error.find(item => item.field == "localGovernment").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "localGovernment").msg }</p>: null}
                        </div>
                        </div>
                    </div> */}

                    <div class="mb-6 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Admission/Registration Number of School</label>
                        <input onChange={(e) => setInstitutionNumber(e.target.value)} ref={institutionNumberRef} maxLength={30} type="titile" id="titile" class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required=""/>
                        {error.find(item => item.field == "institutionNumber").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "institutionNumber").msg }</p>: null}
                    </div>
                </div>
                <div className='flex flex-col xl:flex-row lg:flex-row xl:space-x-4 lg:space-x-4 xl:mb-6 lg:mb-6'>
                    <div class="mb-6 w-full">   
                        <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            A Level Course
                        </label>
                        <div className="mt-2">
                            <select onChange={(e) => setALevelCode(e.target.value)} ref={aLevelCodeRef}
                            id="aLevelCode"
                            name="aLevelCode"
                            autoComplete="country-name"
                            class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                            <option value="">{aLevelLoading ? 'Loading...' : '--select--' }</option>  
                            {aLevelSubjects.map((course, i) => 
                                <option value={course.code}>{`${convertToFourDigits(course.code + '')}: ${course?.subject1}, ${course?.subject2}, ${course?.subject3}`}</option>
                            )}
                            </select>
                            {error.find(item => item.field == "aLevelCode").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "aLevelCode").msg }</p>: null}
                        </div>
                        </div>
                    </div>

                <div class="mb-6 w-full">   
                    <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                        O Level Course
                    </label>
                        <div className="mt-2">
                            <select onChange={(e) => setOLevelCode(e.target.value)} ref={oLevelCodeRef}
                            id="oLevelCode"
                            name="oLevelCode"
                            autoComplete="country-name"
                            class="bg-white border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                            <option value="">{oLevelLoading ? 'Loading...' : '--select--' }</option>  
                            {oLevelSubjects.map((course, i) => 
                                <option value={course.code}>{`${convertToFourDigits((course.code + ''))}: ${ course?.subject1 && course?.subject2 && course?.subject3  ? course?.subject1 + ', ' + course?.subject2 + ', ' + course?.subject3 : course?.subject1 && course?.subject2 ?  course?.subject1 + ', ' + course?.subject2 : course?.subject1 ? course?.subject1 : null} `}</option>
                            )}
                            </select>
                            {error.find(item => item.field == "oLevelCode").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "oLevelCode").msg }</p>: null}
                        </div>
                    </div>
                </div>    

                </div>  

                
                
                
                <div className='flex flex-col mt-4'>      
                <h2>Add O Level Result</h2>  
                <p className='text-bold'>Select minimum of 5 subjects</p>
                {/*subjectErrors.map((error, i) => 
                    <span className='text-red-500'>{error.field + " : " + error.msg + " , "}</span>
                )*/}
                {subjectError ? <p className='text-red-500 mt-8'>Minimum of 5 subjects must be selected</p> : null}
                 {/* row */}
                <div className='flex mt-4'>
                <div class="">      
                        <div className="sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                Exams Type
                            </label>        
                            <div className="">
                                <select onChange={(e) => setExamType1(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Year
                         </label>                                       
                        <input onChange={(e) => setYear1(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class=""> 
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Exam Number
                        </label>        
                        <input onChange={(e) => setExamNumber1(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="-mb-4">   
                        <div className="sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                Subject
                            </label>
                            <div className="">
                                <select onChange={(e) => setSubject1(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Grade
                                </label>        
                                <div className="">
                                <select onChange={(e) => setGrade1(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType2(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear2(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber2(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject2(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade2(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType3(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear3(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber3(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject3(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade3(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType4(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear4(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber4(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject4(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade4(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType5(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear5(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber5(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject5(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade5(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType6(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear6(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber6(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject6(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade6(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType7(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear7(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber7(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject7(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade7(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType8(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear8(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber8(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject8(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade8(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                                 {/* row */}
                                 <div className='flex'>
                <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setExamType9(e.target.value)} id="examType" name="examType" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                        {examsTypeList.map((type, i) => 
                                    <option key={i} value={type}>{type}</option>
                                )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input onChange={(e) => setYear9(e.target.value)} name="year" type="number" min="1900" max="2024" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g 2016" required=""/> 
                    </div>
                    <div class="">
                        <input onChange={(e) => setExamNumber9(e.target.value)} name="examNumber" type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="e.g: 142567" required=""/>
                    </div>
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setSubject9(e.target.value)} id="subject" name="subject" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" >
                                    <option value="">--select--</option>  
                                    {subjectsList.map((subject, i) => 
                                        <option key={i} value={subject}>{subject}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>  
                    <div class="">   
                        <div className="sm:col-span-3">
                            <div className="">
                                <select onChange={(e) => setGrade9(e.target.value)} id="grade" name="grade" autoComplete="country-name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="">--select--</option>  
                                    {gradesList.map((grade, i) => 
                                        <option key={i} value={grade}>{grade}</option>
                                    )}
                                </select>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}




           </div>     










                <div className="sm:col-span-3 mb-6 w-1/2 mt-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Picture</label>
                    <div className="mt-2">
                        <select onChange={(e) => setPhotoMethod(e.target.value)} ref={photoRef}
                        id="gender"
                        name="gender"
                        autoComplete="country-name"
                        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                        <option value="">--select--</option>  
                        <option value={1}>
                            Upload Picture
                        </option>
                        <option value={2}>
                            Capture Live 
                        </option>
                        </select>
                        {error.find(item => item.field == "photo").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "photo").msg }</p>: null}
                    </div>
                </div>  

                <div class="mb-6">
                    <div className='flex space-x-8 items-center w-full'>
                        {photoMethod == 1 ?     
                            <div className="sm:col-span-3 w-full">
                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                Upload your picture
                            </label>
                            
                                {uploadCapture ? 
                                    <div>
                                        <img src= {photoURL} className='w-24 h-24' />
                                        <button onClick={() => {setUploadCapture(false); setPhotoURL(null); setPhoto(null)}} type="button" class="mt-2 text-white bg-red-500 hover:bg-red-600  focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">remove</button>
                                    </div> 
                                    : <div class="flex items-center bg-grey-lighter">
                                    <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                        </svg>
                                        <span class="mt-2 text-base leading-normal">Select a file</span>
                                        <input type='file' class="hidden" onChange={handlePhotoUpload}/>
                                    </label>
                                </div>}
                                {error.find(item => item.field == "photo").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "photo").msg }</p>: null}
                            </div> : photoMethod == 2 ?
                            
                            <div>
                                {liveCapture ? <WebcamCapture setImageCapture={setImageCapture}/> : <div className='flex items-center'>
                                <button onClick={() => setLiveCapture(true)} type="button" class="text-white bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2 -ml-1 hover:w-10 hover:h-10">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                    </svg>
                                    Capture a Live Picture
                                </button>
                            </div>}
                        </div> 
                            : null }  
                        </div>  
                </div> 

                

                {/*<div class="flex items-center mt-8">
                    <label htmlFor="country" className="mr-2 block text-sm font-medium leading-6 text-gray-900">
                        Application Status
                    </label>
                    <div class="flex items-center mt-1 h-5">
                    <input onChange={(e) => {e.target.checked? setStatus(1) : setStatus(0)}} id="status" type="checkbox" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                    </div>
                    {/*<label for="remember" class="ml-2 text-sm font-medium text-gray-500">By submitting this form, you agree with IJMB policies, rules and regulations</label>      
                </div>*/}

                <div class="flex items-center mt-8">
                    
                    <div class="flex items-center h-5">
                    <input onChange={(e) => {e.target.checked? setAgreement(1) : setAgreement(0)}} ref={agreementRef} id="remember" type="checkbox" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required=""/>
                    </div>
                    <label for="remember" class="ml-2 text-sm font-medium text-gray-500">By submitting this form, you agree with IJMB policies, rules and regulations</label>        
                </div>
                {error.find(item => item.field == "agreement").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "agreement").msg }</p>: null}     

                <button onClick={() => setLit(user)} type="button" class="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none mt-8 rounded-lg px-4 py-2 text-center text-lg inline-flex items-center mr-2 mb-2">
                {action}         
                </button> 
            </form>     

            {/*<div className='w-full h-fit mt-8 shadow-sm p-4 bg-slate-50 rounded-md text-slate-200'>
                <p>Your registration form description</p>
                <ol>
                {error.filter(field => field.msg != "").map((item, i) => 
                    
                    <li className='text-red-500 mb-2'>{` ${i}. ${item.field}: ${item.msg}`}</li>
                )}
                </ol>
            </div>*/}
 
        {/* </div> */}    

        </div>
    </div>
        

    )
}

export default NewLit