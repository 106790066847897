//import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { allconfig } from '../config'
import { getData } from './request';

/*export const isLoggedIn = () => {
    readData("accessToken").then(result => {
        if(result == "" || result == undefined){
            window.location.href = `${getAPIBaseURL()}/login`
        }
    })
    .catch(error => {
        
    })
}*/


/*export const storeData = async (key, value) => {
    try {
      await AsyncLocalStorage.setItem(key, value)
    } catch(e) {
      // error
    }
  }*/

/*export const readData = async (key) => {
    let data
  
    try {
      data = await AsyncLocalStorage.getItem(key)
    } catch(e) {
      // error
    }
  
    console.log(data)
    return data
    /*
      output: 
      value
  }*/

  export const getAPIBaseURL = () => {
    var api_base_url = allconfig.env == "dev" ? allconfig.dev_api_url : allconfig.prod_api_url

    return api_base_url;
  }

  export const getSiteBaseURL = () => {
    var site_base_url = allconfig.env == "dev" ? allconfig.dev_site_url : allconfig.prod_site_url
  
    return site_base_url;
  }

  export const getLandingURL = () => {
    var site_base_url = allconfig.env == "dev" ? allconfig.dev_site_url : allconfig.prod_landing_url
  
    return site_base_url;
  }

  export const getUser = () => {
    const user = localStorage.getItem('user')
    if(user){
      return JSON.parse(user)
    }else{
      return null
    }
  }
  
  export const isLoggedUnsecuredToken = () => {
    //alert('logged func')
    let isUserLogged = false
    const token = localStorage.getItem('token')
    const user = localStorage.getItem('user')
    const settings = localStorage.getItem('settings')
    if(token && user && settings){
      //alert('All data are there')
      const decodedJwt = parseJwt(token)
     if(decodedJwt){
        //alert('jwt decoding is fine')
        if(!(decodedJwt.exp * 1000 < Date.now())){
          //alert('valid jwt')
          const userSession = sessionStorage.getItem('active');
          if(userSession){
            //alert('session value: ' + userSession)
            isUserLogged = true
          }
          //alert('No session')
        }else{
          //alert('invalid jwt')
        }   
     }else{
      //alert('jwt decoding not fine')
     }
    }
    return isUserLogged
  }

  export const isLogged = () => {
    //alert('logged func')
    let isUserLogged = false
    const user = localStorage.getItem('user')
    const settings = localStorage.getItem('settings')
    if(user && settings){
      //alert('All data are there')
      const userSession = sessionStorage.getItem('active');
        if(userSession){
            //alert('session value: ' + userSession)
          isUserLogged = true
        }
    }
    return isUserLogged
  }

  

  const parseJwt = (token) => {
    try{
      return JSON.parse(window.atob(token.split(".")[1]))
    }catch(error){
      return null
    }  
  }

  export const getSettings = () => {
    const settings = localStorage.getItem('settings')
    if(settings){
      return JSON.parse(settings)
    }else{
      return null
    }
  }

  export const logout = () => {
    // localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("settings");
    window.location.href = `${getSiteBaseURL()}/login`
  }

  export const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  export const convertToBlob = async (image) => {
    if(typeof(image) == 'string'){
      const blob = await fetch(image).then((res) => res.blob());
      return blob
    }else{
      return image
    }
  }

  export const convertToFourDigits = (number) => {
    let fourDigitsNumber = ''
    if (number.length === 1) {
      fourDigitsNumber = '000' + number
    } else if (number.length === 2) {
      fourDigitsNumber = '00' + number
    } else if (number.length === 3) {
      fourDigitsNumber = '0' + number
    } else if (number.length === 4) {
      fourDigitsNumber = number
    } else {
      fourDigitsNumber = number
    }
    return fourDigitsNumber
  }

  export const formatDateToDash = (date) => {
    //alert(date)
    const [year, month, day] = date.split('-')
    const formatedDate = `${year}-${month}-${day}`
    return formatedDate 
  } 

export const formatDateToDashToServer = (date) => {
  //alert('date to formatL ' + date)
  const [month, day, year] = date.split('/')
  const formatedDate = `${year}-${month}-${day}`
  //alert('Formatted date to server: ' + formatedDate)
  return formatedDate 
}

const getStates = async () => {
  const url = `${getAPIBaseURL()}/v1/states`
  const params = {}
  let returnResult = {}
  let headers = {}
  const requestResult = await getData(url, headers, params)
  if(requestResult.error == ""){
      if(requestResult.result.status === 200){
          let states = requestResult.result.data.data
          returnResult = {status : 200, message: 'success', data: {states}}    
      }else if(404){
          returnResult = {status : 404, message: 'Not found', data: null}
      }else{
          returnResult = {status : 500, message: 'something went wrong', data: null}
      }
  }else{
       returnResult = {status : 500, message: 'there is an  error', data: null}
  }
  return returnResult
}

const getCombinations = async () => {
    const url = `${getAPIBaseURL()}/v1/combinations`
    const params = {}
    let returnResult = {}
    const headers = {}
    const requestResult = await getData(url, headers, params)
    if(requestResult.error == ""){
        if(requestResult.result.status === 200){
            let combinations = requestResult.result.data.data
            returnResult = {status : 200, message: 'success', data: {combinations: combinations}}
        }else if(404){
            returnResult = {status : 404, message: 'Not found', data: null}
        }else{
            returnResult = {status : 500, message: 'something went wrong', data: null}
        }
    }else{
         returnResult = {status : 500, message: 'there is an  error', data: null}
    }
    return returnResult
}

export const getALevelCombinationName = async (code) => {
  const url = `${getAPIBaseURL()}/v1/combination`
  const params = { code }
  let returnResult = {}
  var accessToken = localStorage.getItem('token');
  const headers = { 'token': accessToken }
  const requestResult = await getData(url, headers, params)
  //alert('Code: ' + code)
  if(requestResult.error == ""){
      //alert('No error')
      //alert(requestResult.result.status)
      if(requestResult.result.status === 200){
          //alert("Successful: 200")
          //setNotification(true)
          //alert(JSON.stringify(requestResult.result.data.data))
          //setPage('Lit')
          let combination = requestResult.result.data.data
          combination = `${combination.subject1}, ${combination?.subject2}, ${combination.subject3}`
          //alert('Combination name: ' + combination)
          returnResult = {status : 200, message: 'success', data: {combinationName: combination}}
          
      }else if(404){
          //alert('Not found')
          returnResult = {status : 404, message: 'Not found', data: null}
      }else{
          returnResult = {status : 500, message: 'something went wrong', data: null}
      }

  }else{
       returnResult = {status : 500, message: 'there is an  error', data: null}
      //alert('Error from catch')
      //setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
  }
  return returnResult
  
}

export const getStateName = async (code) => {
   const savedStates = stateExists()
    if(savedStates){
      const state = searchForStateName(savedStates, code)
      return {status: 200, message: 'successful', data: {state}}
    }else{
      const serverResult = await getStates()
      if(serverResult.status == 200){
        const states =  serverResult.data.states
        localStorage.setItem('states', JSON.stringify(states))
      }else{
        return serverResult
      }
    }
}

export const getCombinationName = async (code, trialCount) => {
  if(trialCount){
    const localCombinationsResult = combinationExists()
    if(localCombinationsResult){
      //alert('localCombinationExists')
      //alert(JSON.stringify(localCombinationsResult))
      const combination = searchForCombinationName(localCombinationsResult, code)
      //alert(JSON.stringify(combination))
      const combinationSubjects = `${combination.subject1}, ${combination?.subject2}, ${combination?.subject3}`
      //alert(combinationSubjects)
      return {status: 200, message: 'successful', data: {combinationSubjects}}
    }else{
      //alert(' No local combo. Adding it')
      const DBCombinationsResult = await getCombinations()
      if(DBCombinationsResult.status == 200){
        const combinations =  DBCombinationsResult.data.combinations
        localStorage.setItem('combinations', JSON.stringify(combinations))
        getCombinationName(code, trialCount-1)
      }else{
        return DBCombinationsResult
      }

    }
  }else{
    return {status: 500, message: 'Trial exceeded and combinations not found', data: null}
  }
}

const searchForStateName = (states, code) => {
  const result = states.find((state) => state.code == code);
  //alert(combinationName)
  return result
}

export const stateExists = () => {
  const result = localStorage.getItem('states')
  if(result){
    return JSON.parse(result)
  }else{
    return null
  }
}

export const studentsExists = (centerNumber) => {
  const result = localStorage.getItem(centerNumber)
  if(result){
    return JSON.parse(result)
  }else{
    return null
  }
}

const searchForCombinationName = (combinations, code) => {
  const combinationName = combinations.find((comb) => comb.code == code);
  //alert(combinationName)
  return combinationName
}

export const combinationExists = () => {
  const combinations = localStorage.getItem('combinations')
  if(combinations){
    return JSON.parse(combinations)
  }else{
    return null
  }
}

export const splitSubjects = (subjects) => {
  const [first, second, third] = subjects.split(',')
  //alert(second)
  return {first, second, third}
}

export const splitSubjects2 = (subjects) => {
  //const first = ""
  const splittedSubjects = subjects.split(',')
  const first = splittedSubjects[0].trim() !== 'undefined' ? splittedSubjects[0] : ""
  const second = splittedSubjects[1].trim() !== 'undefined' ? splittedSubjects[1] : ""
  const third = splittedSubjects[2].trim() !== 'undefined' ? splittedSubjects[2] : ""
  //alert(" first " + typeof(first) + "second:" + second + "third:" + third.length)
  return {first, second, third}
}
/*export const splitSubjects3 = (subjects) => {
  const first = subjects[1]
  return {first, second}
}*/


export const formatToWordsDate = (date) => {
  const newDate = new Date(date).toDateString().split(' ')
  const formatedDate = newDate[2] + '-' + newDate[1] + '-' + newDate[3]
  return formatedDate
}