const InfoPopup = ({heading, text, setInfoPopup}) => {
    return (
         
 
<div class="h-screen w-screen fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 flex justify-center items-center bg-gray-600/50">
 <div class="max-w-sm p-2 mx-auto animated fadeIn faster bg-white border-[1px] border-gray-200 shadow rounded-xl hover:shadow-lg transition-all duration-150 ease-linear" x-show="modal"
    >
            <div class="relative p-6">
                <a onClick={() => setInfoPopup(false)} href="#" class="absolute top-1.5 right-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                </a>
                <h1 class="text-2xl font-bold">{heading}</h1>
                <div>
                    <p class="text-red-500 text-sm font-semibold">{text}</p>
                </div>
                <div class="flex flex-row mt-6 space-x-2 justify-evenly">
                    <a onClick={() => setInfoPopup(false)} href="#" class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
                </div>
            </div>
    </div>
    </div>

    )
}
export default InfoPopup