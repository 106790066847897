import axios from "axios";
import { getAPIBaseURL } from "./helpers";
/* const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: getAPIBaseURL()
}) */
//axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';


export const postData = async (url, headers, data) => {
  try{
        //alert(JSON.stringify(headers))
        //alert(url)
        //alert(data) 
        const res = await axios.post(url, data, {headers: headers, withCredentials: true});
         //alert(JSON.stringify(res))
          //alert(JSON.stringify(res))
          //alert('response')
          return {error: "", result: res};
            
      }catch(error){
        //alert(JSON.stringify(error))
        /* if(error?.response?.status == 422){
          window.location.href='/login'
        }
        if(error?.response?.status == 401){
          alert('Invalid details')
        } */
        //alert(JSON.stringify(error?.response))
        return {error: "error", result: error?.response}
      }
}

export const updateData = async (url, headers, data) => {
  try{
        //alert(JSON.stringify(headers))
         const res = await axios.put(url, data, {headers: headers, withCredentials: true});
          //alert(JSON.stringify(res))
          //alert('response')
          return {error: "", result: res};
            
      }catch(error){
        //alert(JSON.stringify(error.response))
        if(error?.response?.status == 422){
          window.location.href='/login'
        }
        return {error: "error", result: error?.response}
      }
}

export const getData = async (url, headers, params) => {
  try{   
      const res = await axios.get(url,  {params:params, headers: headers, withCredentials: true, credentials: 'include' });
      // alert('Success: ' + res)
      return {error: "", result: res};
          
    }catch(error){
      //alert('Status: ' + JSON.stringify(error))
      /*if(error?.response?.status == 422){
        window.location.href='/login'
      }*/
      return {error: "error", result: error?.response}
    }
}

export const deleteData = async (url, headers, params) => {
  try{
          
      const res = await axios.delete(url, {params:params, headers: headers, withCredentials: true});
      //alert(JSON.stringify(res))
      return {error: "", result: res};
          
    }catch(error){
      //alert(JSON.stringify(error.response))
      if(error?.response?.status == 422){
        window.location.href='/login'
      }
      return {error: "error", result: error?.response}
    }
}