import React from 'react'
import abuLogo from '../asset/images/logo1.png' 
import { formatToWordsDate, getALevelCombinationName, getAPIBaseURL, getCombinationName, splitSubjects, splitSubjects2 } from '../services/helpers'
import { getImage } from '../services/localDB';

class StudentListPrintCopy extends React.Component {
    state = {
        aLevelFirst: [],
        aLevelSecond: [],
        aLevelThird: [],
        oLevelFirst: [],
        oLevelSecond: [],
        imagesSrc: [],
        isSet: false
    }
    images = []

    componentDidMount(){
        //this.setState({isSet: true})
        //this.loadImages()
        // alert(this.props.examYear)
        //alert(JSON.stringify(this.props.combinationList2[0]))
        //alert(JSON.stringify(this.props.combinationList2[0].split(',')[0]))
    
        //alert(this.props.combinationList)
        //alert(JSON.stringify(this.props.student))
        //alert(JSON.stringify(this.props.stateNames))
        //alert(this.props.centerNumber)
        //alert(this.props.centerName)
    }
    
    
    

    getCombinationNames = async (combinationCode) => { //change this to a better algorithm by copying the combinations in static file
        if(combinationCode){
            //alert('Combination Code: ' + combinationCode)
            const TRIAL_COUNT = 2
            const combinationResult = await getCombinationName(combinationCode, 0) 
            //alert(JSON.stringify(combinationResult))
            if(combinationResult.status == 200){
                const combinationName = combinationResult.data.combinationName
                //alert('Com Name: ' + combinationName)
                //alert('All is fine')
                const {first, second, third} = splitSubjects(combinationName)
                //alert('First: ' + first + ' Second: ' + second + ' Third: ' + third)
                this.setState( prevState =>  ({aLevelFirst:[...prevState.aLevelFirst, first]}))
                this.setState(prevState => ({aLevelSecond:[...prevState.aLevelSecond, second]}))
                this.setState(prevState => ({aLevelThird:[...prevState.aLevelThird, third]}))
            }else{
                //alert('something went wrong')
            }
        }else{
            this.setState( prevState =>  ({aLevelFirst:[...prevState.aLevelFirst, "..."]}))
            this.setState(prevState => ({aLevelSecond:[...prevState.aLevelSecond, "..."]}))
            this.setState(prevState => ({aLevelThird:[...prevState.aLevelThird, "..."]}))
        }
    }
    render(){
        return(
    <div className='mx-8 my-4 ml-4'>
        <div className=''>    
            <div className='print-logo'>
            <div className='flex justify-start'>
                <img src={abuLogo} className='w-28 h-28'/>
            </div>
            </div>
            <div className='flex flex-col -mt-24 items-center content-center space-y-8'>
                <div className='flex flex-col items-center content-center'>
                    <h1 className='text-xl font-extrabold'>INTERIM JOINT MATRICULATION BOARD</h1>
                    <h1 className='text-lg font-bold'>AHMADU BELLO UNIVERSITY, ZARIA</h1>
                </div>
                <div className='flex flex-col items-center'>
                    <h1 className='text-lg font-bold'><u>CANDIDATE  REGISTRATION LIST FOR {this.props.examYear} EXAMINATION</u></h1>
                    {/* <p className='italic'>This report is printed on ... {formatToWordsDate(new Date().toDateString())}</p> */}
                </div>
                
            </div>
            
            <div className='mt-8 flex space-x-8'>
                <h1 className='text-l g font-bold'>CENTER CODE: {this.props.centerNumber}</h1>
                <h1 className='text-l g font-bold'>CENTER NAME: {this.props.centerName}</h1>
            </div>
        </div>
        <div className=''>    
        <table className='w-full border'>
            <thead className='border-b border-black'>
                {/* <tr>
                    <th>{""}</th>
                    <th>{""}</th>
                    <th>{""}</th>
                    <th>{""}</th>
                    <th colSpan={3} class='border-l text-center'>A` LEVEL SUBJECTS</th>
                    <th colSpan={2} class="border-l">O` LEVEL SUBJECTS</th>
                </tr> */}
                <tr>
                    <th class='border px-2 py-4'>PICTURE</th>
                    <th class='border px-10 py-3'>EXAM NUMBER</th>
                    <th class='border px-10 py-3'>NAME</th>
                    <th class='border px-4 py-3'>GENDER</th>
                    <th class='border px-4 py-3'>NIN</th>
                    <th class='border px-4 py-3'>STATE</th>
                    <th class='border px-4 py-3'>GSM NO</th>
                    <th class='border px-4 py-3'>A LEVEL SUBJECTS</th>
                    <th class='border px-4 py-3'>O LEVEL SUBJECTS</th>
                </tr>
            </thead>
            <tbody>
                {this.props.students.map((student, i) => {
                let subjects = null
                let subjects2 = null
                let second2 = ""
                let empty;
                if(this.props.stateNames && i == 2){
                    // alert(JSON.stringify(this.props.stateNames[i]))
                }
                //let subject3 = null
                if(this.props.combinationList[i]){
                    subjects = splitSubjects(this.props.combinationList[i])
                }
                if(this.props.combinationList2[i]){
                    subjects2 = splitSubjects2(this.props.combinationList2[i])
                    if(typeof this.props.combinationList2[i].split(',')[1] === 'undefined'){
                        console.log('Not undefined')
                        second2 = subjects2.second
                    } 

                    //console.log(this.props.combinationList2[i].split(',')[1])
                    //console.log(subjects2)
                    /*if(this.props.combinationList2[i].length == 2){
                        subjects2 = splitSubjects2(this.props.combinationList2[i])
                    }else{
                        subjects3 = splitSubjects3(this.props.combinationList2[i])
                    }*/
                }
                return(<tr key={i}>
                    <td class='border px-2'><img className='w-16 h-16' src={student?.centerNumber && student?.number && `https://res.cloudinary.com/dnpgrgylk/image/upload/q_50/v${Date.parse(student?.lastImageUpdate)}/IJMB/students/${student?.centerNumber}/pictures/${student?.number}.jpg`}/></td>
                    {/*<td class='border px-2'>{`${this?.props?.preloadedImages[i]}`}</td>*/}
                    <td class='border px-2'>{`${student.number}`}</td>
                    <td class='border px-2'>{`${student.name.surname} ${student.name.firstName} ${student.name.middleName}`}</td>
                    <td class='border px-2'>{student.gender == 1 ? 'M' : 'F'}</td>
                    <td class='border px-2'>{`${student.nin}`}</td>
                    <td class='border px-2'>{`${this.props.stateNames[i] ? this.props.stateNames[i]?.name : ""}`}</td>
                    <td class='border px-2'>{`${student.phoneNumber}`}</td>
                    <td class='border px-4 py-3'>{this.props.combinationList[i]?.trim() !== 'undefined' ? this.props.combinationList[i] : ""}</td>
                    <td class='border px-4 py-3'>
                        {this.props.combinationList2[i]?.split(',')[0]?.trim() !== 'undefined' ? this.props.combinationList2[i]?.split(',')[0]?.trim() : ""}
                        {this.props.combinationList2[i]?.split(',')[1]?.trim() !== 'undefined' ? this.props.combinationList2[i]?.split(',')[1]?.trim() : ""}
                        {this.props.combinationList2[i]?.split(',')[2]?.trim() !== 'undefined' ? this.props.combinationList2[i]?.split(',')[2]?.trim() : ""}
                    </td>
                    
                    {/*<td class='border px-4 py-3'>{this.props.combinationList[i] ? subjects.third : ""}</td>
                    <td class='border px-4 py-3'>{this.props.combinationList[i] ? subjects.third : ""}</td>*/}
                    {/*<td class='border px-4 py-3'>{subjects2?.first ? subjects2?.first : ""}</td>*/}
                    {/*<td class='border px-4 py-3'>{subjects2?.second === 'undefined' || subjects2?.second === undefined || subjects2?.second === null || subjects2.second === "" ? "" : subjects2?.second}</td>*/}
                </tr>
                )})}
            </tbody>
        </table>
        </div>

    </div>
    )}
}
export default StudentListPrintCopy