import {Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import './App.css';
import Login from './dashboard/Login';
import Dashboard from './dashboard/app/';
import AuthVerify from './dashboard/app/AuthVerify';
import { isLogged, isLoggedUnsecuredToken, logout } from './services/helpers';
// import Down from './dashboard/pages/Down';
function App() {
  
 window.onclose = (e) => {
    logout()
  }
  
  const PrivateRoute = () => {
    const logged = isLogged()
    //const logged2 = isLoggedUnsecuredToken()
    // alert('Logged ? ' + logged)
    if(logged){
      return <Outlet/>
    }else{
      return <Navigate to={'/login'} replace />
    }
  }

  const LoggedRoute = () => {
    const logged = isLogged()
    // alert('Logged ? ' + logged)
    if(logged){
      return <Navigate to={'/dashboard'} replace />
    }else{
      return <Outlet/>
    }
  }
  return (
      <Routes> 
        <Route element={<LoggedRoute/>}>
          <Route exact path="/" element={<Login/>} />
          <Route exact path="login" element={<Login/>} />
        </Route>
        <Route element={<PrivateRoute/>}>
          <Route exact path="dashboard" element={<Dashboard/>}/>
        </Route>
      </Routes>
  );
}

export default App;
