import React, { useState } from 'react';
import Webcam from "react-webcam";


const CustomComponent = () => <Webcam />;

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};

export const WebcamCapture = ({setImageCapture}) => {

    const [image,setImage]=useState('');
    const webcamRef = React.useRef(null);

    
    const capture = React.useCallback(
        () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc)
        setImageCapture(imageSrc)
        });


    return (
        <div className="webcam-container">
            <div className="webcam-img">

                {image == '' ? <Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={220}
                    videoConstraints={videoConstraints}
                /> : <img src={image} />}
            </div>
            <div className='mt-2'>
                {image != '' ?
                        <button onClick={(e) => {e.preventDefault(); setImage('')}} type="button" class="text-white bg-yellow-800 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                        Retake Image
                      </button>:
                    <button onClick={(e) => {e.preventDefault(); capture()}} type="button" class="text-white bg-green-800 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                      Capture
                    </button>    
                }
            </div>
        </div>
    );
};