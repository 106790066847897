import {useState, useRef, useEffect} from 'react'
import { getALevelCombinationName, getAPIBaseURL, getCombinationName, getSettings, getSiteBaseURL, getStateName, getUser, splitSubjects, studentsExists } from "../../services/helpers";
import { deleteData, getData } from "../../services/request";
import { validateTitle, validateText } from "../../services/validators";
import Popup from '../../components/Popup';
import EditPopup from '../../components/EditPopup';
import ReactToPrint from 'react-to-print';
import SingleStudentPrintCopy from '../../components/SingleStudentPrintCopy';
import Notification from '../../components/Notification'
import StudentListPrintCopy from '../../components/StudentListPrintCopy';
import { clearImages, fetchAndStoreImages, getImage } from '../../services/localDB';

const Lits = ({setPage, setLastPage, setLit, setThisUser}) => {
    let componentRef = useRef()
    const [user, setUser] = useState({});
    // const [imagesURL, setImagesURL] = useState([])
    const [profilePic, setProfilePic] = useState("")
    const [settings, setSettings] = useState({})
    const [lits, setLits] = useState([])
    const [allLits, setAllLits] = useState([])
    const [oneClickPrint, setOneClickPrint] = useState('One-Click Print')
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [publish, setPublish] = useState("0")
    const [author, setAuthor] = useState("")
    const [limit, setLimit] = useState("10")
    const [skip, setSkip] = useState("0")
    const [bottomLoading, setBottomLoading] = useState(false)
    const [notification, setNotification] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const [deleteName, setDeleteName] = useState("")
    const [pageEnd, setPageEnd] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false)
    const [error, setError] = useState([{field: "title", msg:""}, {field: "text", msg:""}]);
    const [genError, setGenError] = useState("")
    const [updatePopuError, setUpdatePopupError] = useState("")
    const [deletePopupError, setDeletePopupError] = useState("")
    const [searchToken, setSearchToken] = useState('')
    const [combinationList, setCombinationList] = useState([])
    const [combinationList2, setCombinationList2] = useState([])
    const [stateNames, setStateNames] = useState([]) 
    const imagesURL = []
    const [preloadedImages, setPreloadedImages] = useState([]) 
    const [imageData, setImageData] = useState(null);
    const imageIds = []


    useEffect( () => {
        const user = getUser()
        const settings = getSettings()
        if(!user){
          window.location.href = `${getSiteBaseURL()}/login`
        }else{
            //alert(user.number)
            setUser(user)
        }  
        if(settings){
            setSettings(settings)
        }
        //alert(JSON.stringify(settings))
    }, [])

    /*useEffect(() => {
        runAllStudents(2)
    }, [])*/

    useEffect( () => {
        if(notification){
            setTimeout(() => {
                setNotification(false)
            }, 3600)
        }
    }, [notification])

    useEffect( () => {
        // alert('scrolled')
        getLits()
    }, [skip])

    useEffect(() => {
        if(allLits.length && combinationList.length === 0){
            handleCombinationConversion(allLits)
        }
        if(allLits.length && stateNames.length === 0){
            handleStateConversion(allLits)
        }

    }, [allLits])

    useEffect(() => {
        if(allLits.length == 0){
            getAllCenterStudentsFromServer()
        }
    }, [])

    /*useEffect(() => {
        if(allLits.length){
            //clearImages()
            const thisImageIds = fetchAndStoreImages(user.number, allLits)
            imageIds.push([...thisImageIds])
        }
    }, [allLits])*/

    /*useEffect(() => {
        if(imageIds.length != 0){
            loadImages()
        }
    }, [imageIds])*/

    useEffect(() => {
        if(notification){
            const updatedLit = lits.filter(lit => lit._id !== deleteId)
            setLits(updatedLit)
        }
    }, [notification])

    /* useEffect(() => {
        //alert('Original Combo Length: ' + combinationList.length)
    }, [combinationList])*/

    window.onscroll = (e) => handleScroll(e)

    const handleScroll = (e) => {
      if(window.innerHeight + e.target.documentElement.scrollTop + 1 >= e.target.documentElement.scrollHeight){
        setSkip(lits?.length)
        //alert('height reached')
    }else{
        //alert('height not reached')
      }
    }

    const DeletePopup = () => {
        <Popup/>
    }
  
    const containsToken = (item) => {
        const nameSearch = item.name.firstName.toLowerCase().includes(searchToken.toLowerCase()) || item.name.surname.toLowerCase().includes(searchToken.toLowerCase()) || item.name.middleName.toLowerCase().includes(searchToken.toLowerCase())
        const emailSearch = item.email.toLowerCase().includes(searchToken.toLowerCase())
        const candidateNumber = item.number.includes(searchToken.toLowerCase())
        const examYear = item.examYear.includes(searchToken.toLowerCase())
        
        if(nameSearch || emailSearch || candidateNumber || examYear){
            return true
        }else{
            return false
        }
    }

    {/* const loadImages = async () => {
        alert('Images loader')
        for(let i=0; i< allLits; i++){
            const imageEntry = await getImage(i);
            if (imageEntry) {
            //this.setState(imageEntry.imageData);
            alert('Image ' + i) 
            preloadedImages.push(imageEntry.imageData)
            }else{
                alert('No image')
            }
        }
      }; */}

    /*const preloadImages = () => {
        allLits.forEach((student) => { 
            const img = new Image();
            //alert(student.number)
            //alert(user.number)
            let currentImage = `https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${user.number}/${student.number}.jpg`
            img.src = currentImage
            //setPreloadedImages([...preloadedImages, img])
            window[currentImage] = img
            // setPreloadedImages([...preloadedImages, ])
        })
    }*/

    
    
      

    /*const runAllStudents = async (trialCount) => {
        if(trialCount !== 0){
            const savedStudents = studentsExists(user?.number)
            if(savedStudents){
                setAllLits(savedStudents)
                //alert('Saved students')
            }else{
                const studentsResult = await getAllCenterStudentsFromServer()
                //alert(JSON.stringify(studentsResult))
                if(studentsResult.status == 200){
                    //alert('successful run')
                    localStorage.setItem(user?.number, JSON.stringify(studentsResult.data.students))
                    await runAllStudents(trialCount-1) 
                }else{
                    //alert('Something went wrong')   
                }
            }
        }else{
            //alert('You exhausted your trial count')
        }
    }*/

    
    const getAllCenterStudentsFromServer = async () => {
        //alert('Hi')
        const user = getUser()
        let result = {}
        if(user){
            const url = `${getAPIBaseURL()}/v1/students/center`;
            const headers = {}
            const params = {centerId: user._id}
            setOneClickPrint('Loading...')
            const requestResult = await getData(url, headers, params)
            //alert(JSON.stringify(requestResult))    
            if(requestResult.error == ""){
                    if(requestResult.result.status === 200){
                        //alert(JSON.stringify(requestResult.result.data.data))
                        setAllLits([...requestResult.result.data.data])
                        //result = {status: 200, message: 'successful', data: { students: requestResult.result.data.data }}
                        //alert('success')
                    }else{
                        //result = {status: 500, message: 'something went wrong', data:  null }    
                        //alert('something 1')
                        setGenError('something went wrong')
                    }

                }else{
                    //result = {status: 500, message: 'something also went wrong', data:  null }
                    //alert('something 2')
                    setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                }
                setOneClickPrint('One-Click Print')
            }else{
                //result = {status: 500, message: 'user credential error', data:  null }
            }  
            //return result
    }

    const getLits = async () => {
        const user = getUser()
        if(user){
            const url = `${getAPIBaseURL()}/v1/students/center`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = {}
            const params = {centerId: user._id, limit:limit , skip:skip};
            const requestResult = await getData(url, headers, params)
            // alert(JSON.stringify(requestResult))    
            if(requestResult.error == ""){
                    if(requestResult.result.status === 200){
                        // alert(JSON.stringify(requestResult.result.data.data[0].subjects[0]))
                        //const rawSubjects = JSON.parse(requestResult.result.data.data[0].subjects[0])
                        //alert(rawSubjects[0].subject1)
                        setLits([...lits, ...requestResult.result.data.data])
                    }else{
                        setGenError('something went wrong')
                    }

                }else{
                    setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                }
            }  
    }

    const deleteStudent = async (studentId) => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('Delete Student')
        // var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        // if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user){
            //alert("going")
            const url = `${getAPIBaseURL()}/v1/students`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            //const headers = {'token': accessToken}
            const headers = {}
            const params = {studentId: studentId};

            const requestResult = await deleteData(url, headers, params)
            
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    //alert("Successful: 200")
                    //setNotification(true)
                    //alert(JSON.stringify(requestResult.result.data.data))
                    //setPage('Lit')
                    //setLits([...lits, ...requestResult.result.data.data])
                    localStorage.removeItem(user?.number)
                    setDeletePopup(false)
                    setNotification(true)
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                //alert('error')
                setDeletePopupError(requestResult.result.response.data?.message ? requestResult.result.response.data?.message : requestResult.result.response.data?.data)
                setNotification(true)    
                //setGenError(requestResult.result.response.data?.message ? requestResult.result.response.data?.message : requestResult.result.response.data?.data)
            }
            
            
            /*if(request.error == ""){
                if(request.result.data.error == ""){
                    //alert(JSON.stringify(request.result.data.result))
                    if(request.result.data.result.length == 0){
                        setPageEnd(true)
                        setBottomLoading(false)
                    }else{
                        setLits([...lits, ...request.result.data.result])
                    }
                    //window.location.href = `${getAPIBaseURL()}/app`

                }else{
                    //alert("Eror")
                    setGenError(request.result.data.result)
                }

            }else{
                setGenError("Something went wrong")
            }*/
            
        }  
    }

    const handleOneClickPrint = () => {
        // componentsRef.current.forEach((component) => {
         // component.handlePrint();
       //  });
    };

    const handleStateConversion = async (students) => { //change this to a better algorithm by copying the combinations in static file
        const tempStates = []
        if(students.length){
            for(let student of students){
                let code = student.address.state
                if(code){
                    const result = await getStateName(code) 
                    if(result.status == 200){
                        let state = result.data.state 
                        tempStates.push(state)
                    }else{
                        tempStates.push("")
                    }
                }else{
                    tempStates.push("")
                }
            }
            setStateNames([...tempStates])
        }
    }

    const handleCombinationConversion = async (students) => { //change this to a better algorithm by copying the combinations in static file
        const tempCombos = [] 
        const tempCombos2 = []
        if(students.length){
            for(let student of students){
                let combinationCode = student.aLevelCode
                let combinationCode2 = student.oLevelCode    
                if(combinationCode){
                    const TRIAL_COUNT = 1
                    const combinationResult = await getCombinationName (combinationCode, TRIAL_COUNT) 
                    // alert(JSON.stringify(combinationResult))
                    if(combinationResult.status == 200){
                        let combo = combinationResult.data.combinationSubjects 
                        tempCombos.push(combo)
                    }else{
                        tempCombos.push("")
                    }
                }else{
                    tempCombos.push("")
                }
                if(combinationCode2){
                    const TRIAL_COUNT = 1
                    const combinationResult = await getCombinationName (combinationCode2, TRIAL_COUNT) 
                    if(combinationResult.status == 200){
                        let combo = combinationResult.data.combinationSubjects 
                        tempCombos2.push(combo)
                    }else{
                        tempCombos2.push("")
                    }
                }else{
                    tempCombos2.push("")
                }
            };
            setCombinationList([...tempCombos])
            setCombinationList2([...tempCombos2])
        }
    }


    

    const navigateToEditPage = (lit) => {
        setLit(lit)
        setPage("EditLit")
        setLastPage("Lits")
    }
    const navigateToViewPage = (lit) => {
        setLit(lit)
        setPage("SingleLit")
        setLastPage("Lits")
    }
    

    return(
        <div>
             {notification && <Notification message={'Student successfully deleted'} setNotification={setNotification} />}
        <section class="md:mx-12 text-gray-600 body-font">
        <div class="container px-5 py-10 mx-auto">
            
          <div class="flex flex-wrap w-full mb-4 justify-between text-center">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Candidates</h1>
            {settings?.registration ? 
                <button onClick={() => setPage("NewLit")} type="button" class="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-md text-md px-4 py-2 text-center inline-flex items-center dark:focus:ring-black/55 mr-2 mb-2">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    New Student
                </button> 
                :
                <button disabled type="button" class="text-white bg-gray-400 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    New Student
                </button>
            }
          </div>

          <div className='flex flex-col gap-2 text-md font-mono font-bold mb-4 text-gray-900'>
            <h1>INTERIM JOINT MATRICULATION BOARD (IJMB)</h1>
            <h1>AHMADU BELLO UNIVERSITY, ZARIA</h1>
            <h1>EXAMINATION CANDIDATES REGISTRATION CENTER</h1>
            <h1><div className='py-2 px-4 bg-red-500 text-white inline'>{user.number}</div>: {user.name}</h1>
          </div>
          {/*genError && <div><p className='text-red-500'>{genError}</p> </div>*/}
          {deletePopup && <Popup name={deleteName} deleteStudent={deleteStudent} studentId={deleteId} setDeletePopup={setDeletePopup} deletePopupError={deletePopupError} />}
          {editPopup && <EditPopup/>}
          
          {/*<div class="flex flex-wrap -m-4">*/}



                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <div class="flex flex-wrap gap-4 items-center justify-between py-4 bg-white mx-4">
                        <div>
                            {/*<button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 " type="button">
                                <span class="sr-only">Action button</span>
                                Action
                                <svg class="w-3 h-3 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                            </button>*/}
                                <div>
                                    <ReactToPrint trigger={() => <button onClick={ ()  =>  window.print()} type="submit" class="text-black border-2 hover:border-blue-500 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-4">{oneClickPrint}</button>} content={() => componentRef} />
                                    <div class='print-source'><StudentListPrintCopy students={allLits} stateNames={stateNames} combinationList={combinationList} combinationList2={combinationList2} examYear={settings.examYear} centerNumber={user.number} centerName={user.name}  ref={(el) => (componentRef = el)}/></div>
                                </div>    
                                {/*<button onClick={ () => window.print()} type="submit" class="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-4">Print All</button>*/}
                                
                            <div id="dropdownAction" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
                                <ul class="py-1 text-sm text-gray-700 " aria-labelledby="dropdownActionButton">
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Reward</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Promote</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Activate account</a>
                                    </li>
                                </ul>
                                <div class="py-1">
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Delete User</a>
                                </div>
                            </div>
                        </div>
                        <label for="table-search" class="sr-only">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input onChange={(e) => setSearchToken(e.target.value)} type="text" id="table-search-users" class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search for candidates"/>
                        </div>
                    </div> 
                    {combinationList.map((combo, i) => 
                        <div>
                            <p>{combo.abba}</p>
                        </div>
                    )}
                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                {/*<th scope="col" class="p-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                    </div>
                                </th>*/}
                                <th scope="col" class="py-3 px-6">
                                    Name
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Candidate Number
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Exam Year
                                </th>
                                {/*<th scope="col" class="py-3">
                                    Status
                                </th>*/}
                                <th scope="col" class="py-3 px-6">
                                    Edit
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lits.filter(containsToken).map((student, i) => {
                                    //imagesURL.push(`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${student.centerNumber}/${student.number}?q=${10}&timestamp=${new Date().getTime()}.jpg`)
                                    //imagesURL.push(`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${student.centerNumber}/${student.number}?q=${10}&timestamp=${new Date().getTime()}.jpg`)
                                    
                            {/*setImagesURL([...imagesURL, `https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${student.centerNumber}/${student.number}.jpeg`])*/}
                                    return(
                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                
                                    <td onClick={() => navigateToViewPage(student)} scope="row" class="flex items-center cursor-pointer px-6 py-4 text-gray-900 whitespace-nowrap px-6">
                                        <img class="w-10 h-10 rounded-full" src={student?.centerNumber && student?.number && `https://res.cloudinary.com/dnpgrgylk/image/upload/q_50/v${Date.parse(student?.lastImageUpdate)}/IJMB/students/${student?.centerNumber}/pictures/${student?.number}.jpg`} alt="Jese image"/>                                   
                                        {/*<img class="w-10 h-10 rounded-full" src={student?.centerNumber && student?.number && imagesURL[i]} alt="Jese image"/>*/}
                                        <div class="pl-3">
                                            <div class="text-base font-semibold">{`${student.name.firstName} ${student.name.middleName} ${student.name.surname}`}</div>
                                            <div class="font-normal text-gray-500">{student.nin}</div>
                                        </div>  
                                    </td>
                                    <td class="py-4  px-6">
                                        { `${student.number}`}
                                    </td>
                                    <td class="py-4  px-6">
                                        <div class="flex items-center">
                                           </div> {student.examYear}
                                    </td>
                                    {/*<td class="py-4">
                                        {student.status ? 
                                        <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        :
                                        <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        } 
                                    </td>*/}
                                    <td class="py-4  px-6">
                                        {settings?.modification ? <button onClick={() => navigateToEditPage(student)} href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-blue-600  hover:underline">Edit</button> : <a href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-gray-400  hover:underline">Edit</a>}
                                    </td>
                                    <td class="py-4  px-6">
                                        {settings?.deletion ? <button onClick={() => { setDeletePopup(true); setDeleteId(student._id); setDeleteName(`${student.name.surname} ${student.name.firstName} ${student.name?.middleName}` ) }} href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-blue-600  hover:underline">Delete</button> : <a href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-gray-400  hover:underline">Delete</a>}
                                    </td>
                                </tr>
                            )})}
                            
                        </tbody>
                    </table>
                    

                    <div id="editUserModal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
                        <div class="relative w-full max-w-2xl max-h-full">
                          
                            <form action="#" class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                
                                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                        Edit user
                                    </h3>
                                    <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="editUserModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                                    </button>
                                </div>
                                
                                <div class="p-6 space-y-6">
                                    <div class="grid grid-cols-6 gap-6">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="first-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                            <input type="text" name="first-name" id="first-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Bonnie" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="last-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                            <input type="text" name="last-name" id="last-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Green" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                            <input type="email" name="email" id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="example@company.com" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="phone-number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                            <input type="number" name="phone-number" id="phone-number" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. +(12)3456 789" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="department" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                                            <input type="text" name="department" id="department" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Development" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company</label>
                                            <input type="number" name="company" id="company" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123456" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="current-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Password</label>
                                            <input type="password" name="current-password" id="current-password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="new-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                            <input type="password" name="new-password" id="new-password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required=""/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            {/*lits.map((lit, i) => 
                <div onClick={() => changePage(lit)} class="xl:w-1/3 md:w-1/2 p-4 cursor-pointer">
                <div class="border border-gray-200 p-6 rounded-lg">
                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                    <img class="w-10 h-10 rounded-full" src='https://pbs.twimg.com/profile_images/1486965541948600322/P3ecN5nG_400x400.jpg' /> 
                    </div>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-2 truncate">{lit.firstName}</h2>
                    <p class="leading-relaxed text-base block line-clamp-2">{`${lit.name.firstName} ${lit.name.middleName} ${lit.name.surname}`}</p>
                    <div class="flex justify-between mt-4">
                        <p class="flex self-end">{lit.status == "0"? "inactive": "active"}</p>
                        <p>{`${lit.number}`}</p>
                    </div>
                </div>
                </div>
            )*/}
            
          {/*</div>*/}
        </div>
        {bottomLoading && <div className='mx-auto py-6'><p>Loading...</p></div>}
      </section>
      </div>
    )
}



export default Lits;