import { openDB } from 'idb';

const dbName = 'imageDatabase';
const storeName = 'images';

const dbPromise = openDB(dbName, 1, {
  upgrade(db) {
    db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
  },
});

const saveImage = async (imageData) => {
    const db = await dbPromise;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const existingImage = await store.index('imageData').get(imageData);
    if (existingImage) {
      alert('Image already exists. Not saving.');
      return existingImage.id;
    }
    const id = await store.add({ imageData });
    await tx.done;
    alert('Id: ' + id + ' Type: ' + typeof(id))
    return id;
  };

const getImage = async (id) => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  return store.get(id);
};

export const clearImages = async () => {
    const db = await dbPromise;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    await store.clear();
    await tx.done;
    alert('Images cleared')
};

/*const fetchAndStoreImages = async (imageURLs) => {
  for (const url of imageURLs) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = async () => {
        await saveImage(reader.result);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error(`Error fetching or storing image from URL ${url}:`, error);
    }
  }
};*/

const fetchAndStoreImages = async (centerNumber, students) => {
    const imageIds = []
    for (const student of students) {
        alert('Fetching image')
        try {
        const response = await fetch(`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${centerNumber}/${student.number}?q=${10}.jpg`);
        const blob = await response.blob();
        const reader = new FileReader();
  
        reader.onloadend = async () => {
          const id = await saveImage(reader.result);
          imageIds.push(id)
           alert('Id: ' + id) 
        };
        // alert(id)
        reader.readAsDataURL(blob);
      } catch (error) {
        alert(error)
        console.error(`Error fetching or storing image from URL ${student}:`, error);
      }
    }
    alert('imageIds: ' + imageIds)
    return imageIds
};

export { fetchAndStoreImages, getImage };
