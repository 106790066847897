import React, {useState, useEffect} from "react"
import { getAPIBaseURL, getSiteBaseURL } from "../services/helpers";
import { postData } from "../services/request";
import { validatePassword, validateUsername } from "../services/validators";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [btnState, setBtnState] = useState("Sign in");
    const [error, setError] = useState([{field: "username", msg:""}, {field: "password", msg:""}]);
    const [genError, setGenError] = useState("")
    


    const login = async () => {
        //alert("username: " + username + " Password: " + password);
        var e_val = validateUsername(username).error == "" ? true: false;
        var p_val = validatePassword(password).error == "" ? true: false;
        setError([...error, error.find(item => item.field == "username").msg = validateUsername(username).result])
        setError([...error, error.find(item => item.field == "password").msg = validatePassword(password).result])
        
        if(e_val && p_val){
            setBtnState("Loading...")
            //alert('Ready')
            //alert('API URL: ' + getSiteBaseURL())
            const url = `${getAPIBaseURL()}/v1/centers/login`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!'
            const headers = {'Content-Type' : 'multipart/form-data'}
            const data = {centerNumber: username, password: password};
            const formData = new FormData()
            formData.append('centerNumber', username)
            formData.append('password', password)
            const requestResult = await postData(url, headers, formData)
            
            setBtnState('Sign in')
            //alert(JSON.stringify(requestResult))
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    //alert("Success")
                    //alert(JSON.stringify(requestResult.result.data.data))
                    localStorage.setItem('user', JSON.stringify(requestResult.result.data.data))
                    localStorage.setItem('settings', JSON.stringify(requestResult.result.data.settings))
                    // localStorage.setItem('token', requestResult.result.data.token)
                    sessionStorage.setItem('active', true)
                    /*localStorage.setItem('_id', requestResult.result.data.result.result._id);
                    localStorage.setItem('jwt_token', requestResult.result.data.result.token);
                    localStorage.setItem('username', requestResult.result.data.result.result.username);
                    localStorage.setItem('name', requestResult.result.data.result.result.name);
                    */
                    //alert(requestResult.result.data.token)
                    window.location.href = `${getSiteBaseURL()}/dashboard`

                }else{
                    setGenError('something went wrong')
                    //setBtnState('Sign in')
                    //alert("Error")
                }

            }else{
                //alert(JSON.stringify(requestResult.result))
                //alert('Not known')
                setGenError(requestResult.result.data.message)
                //setBtnState("Sign in")
            }
            //setBtnState("Sign in")    
        }  
        //setBtnState("Sign in")
    }

    return(
        <section class="bg-gray-50">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold">
                {/*<img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo"/>*/}
                IJMB Login
            </a>
            <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold leading-tight tracking-tight md:text-2xl dark:text-white">
                        Sign in to your account
                    </h1>
                    <div class="space-y-4 md:space-y-6">
                        {genError != "" ? <div class="text-red-500 text-sm font-semibold">{genError}</div> : ""}
                        <div>
                            <label for="username" class="block mb-2 text-sm font-medium">Your username</label>
                            <input value={username} onChange={(e) => setUsername(e.target.value)} type="username" name="username" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="" required=""/>
                            {error.find(item => item.field == "username").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "username").msg }</p>: null}
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                            {error.find(item => item.field == "password").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "password").msg }</p>: null}
                        </div>
                        {/*<div class="flex items-center justify-between">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""/>
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                                </div>
                            </div>
                            <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                        </div>*/}
                        <button onClick={() => login()} type="submit" class="w-full text-white bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{btnState}</button>
                        {/*<p class="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don’t have an account yet? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                        </p>*/}
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Login