exports.subjectsList = [
  "AGRICULTURAL SCIENCE",
  "ARABIC",
  "AUTO MECHANICS",
  "BASIC ELECTRICITY",
  "BASIC ELECTRONICS",
  "BIOLOGY",
  "BUILDING CONSTRUCTION",
  "CHEMISTRY",
  "CHRISTIAN RELIGIOUS STUDIES",
  "CIVIC EDUCATION",
  "CLOTHING AND TEXTILES",
  "COMMERCE",
  "ECONOMICS",
  "ENGLISH LANGUAGE",
  "FINANCIAL ACCOUNTING",
  "FOODS AND NUTRITION",
  "FRENCH",
  "FURTHER MATHEMATICS",
  "GENERAL MATHEMATICS",
  "GEOGRAPHY",
  "GOVERNMENT",
  "HAUSA",
  "HEALTH EDUCATION/HEALTH SCIENCE",
  "HISTORY",
  "HOME MANAGEMENT",
  "IGBO",
  "ISLAMIC STUDIES",
  "LITERATURE IN ENGLISH",
  "METAL WORK",
  "MUSIC",
  "PHYSICAL EDUCATION",
  "PHYSICS",
  "TECHNICAL DRAWING",
  "VISUAL ART",
  "WOODWORK",
  "YORUBA"
];

exports.gradesList = [
  "A1",
  "B2",
  "B3",
  "C4",
  "C5",
  "C6",
  "D7",
  "E8",
  "F9"
];

exports.examsTypeList = [
  "NABTEB",
  "NECO",
  "WAEC"
];
