const Notification = ({message, setNotification}) => {
    return(
        <div id="toast-success" class="sticky top-0 flex items-center justify-between w-full p-2 pr-1 mb-4 text-white bg-green-700 shadow" role="alert">
                <div className='flex items-center'>
                    <div class="inline-flex items-center justify-center items-center flex-shrink-0 w-6 h-6 text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200">
                        <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="sr-only">Check icon</span>
                    </div>
                    <div class="ml-2 text-lg font-normal">{message}</div>
                </div>
                <svg onClick={() => setNotification(false)} aria-hidden="true" class="w-6 h-6 text-white hover:text-white/80 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </div>
    )
}

export default Notification
